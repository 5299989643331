export const regexReference = /^[a-zA-Z0-9]{1,50}$/;
export const regexVIN = /^[a-zA-Z0-9]{4,20}$/;
export const regexVINForInput = /^[a-zA-Z0-9]{1,17}$/;
export const regexPermisConduire = /^[a-zA-Z0-9]{1,19}$/;
export const regexCodeSociete = /^\d{8}$/;
export const regexCodeSociete_for_input = /^\d{1,8}$/;
export const regexSiren = /^\d{9}$/;
export const regexCodeNic = /^\d{5}$/;
export const regexSirenSiret = /\b\d{14}\b|\b\d{9}\b/;
export const regexSiretForInput = /^\d{1,14}$/;
export const regexMarque = /^[a-zA-Z0-9]{1,50}$/;
// Regexs issues du fichier XSD (liste non exhaustive)
export const RIE_Nom_Type_restriction = /^[A-Za-zÀ-ÿ \-']{1,50}$/;
export const RIE_Prenom_Type_restriction = /^[A-Za-zÀ-ÿ \-']{1,50}$/;
export const RIE_RaisonSociale_restriction = /^[0-9A-Za-zÀ-ÿ\-.'_ ]{1,50}$/;
export const RIE_NumeroImmatriculation_restriction = /^[a-zA-Z0-9\s-]{3,20}$/;
export const RIE_NumeroImmatriculation_restriction_for_input = /^[a-zA-Z0-9\s-]{1,20}$/;
// Contrôles suppleméntaires par rapport au XSD pour le numéro de police
export const RIE_NumeroPolice_restriction = /^[-* /A-z0-9]{1,50}$/;
export const RIE_ReferenceMandat_restriction = /^[0-9a-zA-Z\-._]{1,50}$/;
export const RIE_AncienneteCRM_restriction = /^(?:[1-9]|[1-4][0-9]|50)$/;
export const RIE_Debut_Nom_Type_restriction = /^[A-Za-zÀ-ÿ \-']{3,50}$/;
export const regexCodeSocieteInput = /^[A-Za-z0-9]*$/;
