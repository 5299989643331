import { FieldMetadata, getInputProps } from "@conform-to/react";
import { TextField, TextFieldProps } from "@mui/material";

export type InputProps = TextFieldProps;

export function Input(props: InputProps) {
  return (
    <TextField
      variant="filled"
      size="small"
      margin="none"
      FormHelperTextProps={{ style: { fontWeight: "bold" } }}
      sx={{
        width: props.fullWidth ? "100%" : "300px",
        color: "primary.main",
        "input-label": {
          color: "primary.main",
        },
        input: {
          color: "primary.main",
        },
      }}
      {...props}
    />
  );
}

export type InputConformProps = TextFieldProps & {
  field: FieldMetadata<any, any, any>;
};

export function InputConform({ label, field, ...props }: InputConformProps) {
  return (
    <Input
      label={label}
      {...getInputProps(field, { type: "text" })}
      error={Boolean(field.errors)}
      helperText={field.errors}
      data-cy={field.name}
      {...props}
    />
  );
}
