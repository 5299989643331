import { parse, format, isValid } from "date-fns";
import { VALEUR_NON_DEFINI } from "model/schema";

export const FORMAT_DATE = "dd/MM/yyyy";
export const FORMAT_ISO = "yyyy-MM-dd";
export const FORMAT_DATE_TIME = "yyyy-MM-ddTHH:mm:ss.t";

export const toDate = (str?: string | null, _format = FORMAT_DATE) => {
  try {

    if (!str) return null;
    const parsedDate = parse(str, _format, new Date());
    return isValid(parsedDate) ? parsedDate : null;
  } catch {
    return null;
  }
};


export const formatSafe = (date?: Date | string | null, _format = FORMAT_DATE) => {
  try {
    if (!date) return null;
    if (date instanceof Date) {
      return format(date, _format);
    }
    return format(new Date(date), _format);
  } catch {
    return null;
  }
};

export const formatSafeDateTime = (date?: string | null, _format = "dd/MM/yyyy à HH:mm") => {
  try {
    if (!date) return null;
    return format(new Date(date), _format);
  } catch {
    return null;
  }
};

export const isDateValid = (date: Date) => !Number.isNaN(date.valueOf());

export const printDateNaissance = (date?: Date | null, datePartiel: boolean = false) => {
  try {
    return formatSafe(date, datePartiel ? "MM/yyyy" : FORMAT_DATE) || VALEUR_NON_DEFINI;
  } catch {
    return VALEUR_NON_DEFINI;
  }
};