import {
  HabilitationApi,
  HabilitationFormCreate,
  HabilitationFormDisable,
  HabilitationFormEdit,
  HabilitationQuery,
} from "pages/GestionHabilitation/habilitationSchema";
import { baseUrl } from "./baseUrl";
import { downloadFile, fetchJSON } from "./fetchJSON";
import { FORMAT_ISO, formatSafe } from "utils/toDate";

const getUrlCancelId = (id: string) => new URL(`/backend/v1/habilitations/delete/${id}`, baseUrl);
const getUrlId = (id: string) => new URL(`/backend/v1/habilitations/${id}`, baseUrl);
const getUrl = () => new URL("/backend/v1/habilitations", baseUrl);

export async function getHabilitationList(query: HabilitationQuery) {
  const url = getUrl();
  for (let [key, value] of Object.entries(query)) {
        if (value && value !== "Tous") {
      url.searchParams.set(key, String(value));
    }
    if (key === "debutHabilitation" || key === "finHabilitation") {
      url.searchParams.set(key, String(formatSafe(String(value), FORMAT_ISO)));
    }
  }
  if (!url.searchParams.toString().includes("size")) {
    url.searchParams.set("size", "25");
  }
  return fetchJSON<HabilitationApi>(url);
}

export async function createHabilitation(form: HabilitationFormCreate) {
  const data = {
    codeGestionnaire: form.codeGestionnaire,
    debutHabilitation: formatSafe(form.debutHabilitation, FORMAT_ISO),
    finHabilitation: formatSafe(form.finHabilitation, FORMAT_ISO),
    codePerimetre: form.perimetre,
  };
  return fetchJSON(getUrl(), { method: "POST", data });
}

export async function updateHabilitation(form: HabilitationFormEdit) {
  const data = {
    codeGestionnaire: form.codeGestionnaire,
    debutHabilitation: formatSafe(form.debutHabilitation, FORMAT_ISO),
    finHabilitation: formatSafe(form.finHabilitation, FORMAT_ISO),
    codePerimetre: form.perimetre,
  };
  return fetchJSON(getUrlId(form.idHabilitation), { method: "PUT", data });
}

export async function disabledHabilitation(form: HabilitationFormDisable) {
  return fetchJSON(getUrlCancelId(form.idHabilitation), { method: "PUT" });
}

export async function downloadHabilitation(query: HabilitationQuery) {
  const url = new URL("/backend/v1/downloadHabilitations", baseUrl);
  for (let [key, value] of Object.entries(query)) {
    if (key !== "page") url.searchParams.set(key, String(value));
  }
  return downloadFile(url, "habilitations.csv");
}

export async function downloadHabilitationsActif() {
  const url = new URL("/backend/v1/downloadHabilitationsActif", baseUrl);
  return downloadFile(url, "HabilitationsActives.csv");
}

export async function downloadAllIntermediaires() {
  const url = new URL("/backend/v1/downloadIntermediaireInscrits", baseUrl);
  return downloadFile(url, "intermediairesInscrit.csv");
}
